import type { DirectiveBinding } from 'vue';
import type { DataObject } from 'o365-dataobject';
import { addEventListener } from './composables.EventListener.ts'

const cancelTokens = new Map<string, Function>();

const vDataObjectSave = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const uid = crypto.randomUUID();
        el.dataset.saveId = uid;
        const ct = addEventListener(el, 'keydown', (pEvent: KeyboardEvent) => {
            if (pEvent.ctrlKey && ['s','S'].includes(pEvent.key)) {
                pEvent.preventDefault();
                const dataObject = binding.value as DataObject;
                if (dataObject) {
                    if(Array.isArray(dataObject)){
                        dataObject.forEach(x=>{
                            x.save();
                        })  
                    }else{
                        dataObject.save();  
                    }

                }
            }
        });
        cancelTokens.set(uid, ct);
    },
    beforeUnmount(el: HTMLElement) {
        const uid = el.dataset.saveId;
        const ct = cancelTokens.get(uid ?? '');
        if (ct) {
            ct();
            cancelTokens.delete(uid!);
        }
    },
};

export default vDataObjectSave;